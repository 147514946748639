import { useState, FC, PropsWithChildren, useEffect } from 'react'
import { NextComponentType, NextPageContext } from 'next'
import Head from 'next/head'
import type { AppProps as NextAppProps, AppContext } from 'next/app'
import { Session } from 'next-auth'
import { type DehydratedState } from '@tanstack/query-core'
import { AppProviders, AppProvidersProps, RollbarProviders } from 'src/app/providers'
import { CheckAuthAndAppErrors, NotEnabledCompanyError } from 'src/app/errors'
import { Layout } from 'src/components/layout'
import { AuthProviders } from 'src/auth'
import { TokenWatcher } from 'src/auth/token-watcher'
import { NavConfig } from 'src/data/config'
import { useMatomoTrackSlugChange } from 'src/utils/analytics'
import { AppConfig } from 'src/api/centre-service/data-models/app-config'
import { performIfNativeApp } from 'src/utils/clients/native/perform-if-native-app'
import { setupNativeApp } from 'src/app/native-app-setup/setup-native-app'
import { initBackButtonListener } from 'src/utils/clients/native/android-back-listener'
import getClient from 'src/utils/clients/get-client'
import { IntercomScript } from './intercom'
import { migrateFavoriteLocationIds } from 'src/app/migrators/migrate-favorite-location-ids'
import { migratePhase1AnonymousGroupId } from 'src/app/migrators/migrate-anonymous-group-id'
import useOnce from 'src/utils/hooks/use-once'
import { ConfigCatProvider } from 'src/utils/config-cat'
import getConfigCatUser from 'src/utils/config-cat/getConfigCatUser'
import { DatadogRumMonitor } from 'src/utils/datadog'
import NoServerSideRendering from 'src/components/utils/NoServerSideRendering'
import dynamic from 'next/dynamic'
import { ENV } from 'src/utils/env'

const MockProvider = ENV.MOCK_MODE
  ? dynamic(() => import('src/app/MockProvider'))
  : ({ children }) => children

export * from 'src/app/helpers'

interface AppComposition {
  getInitialProps?: (c: AppContext) => Promise<any>
}

interface AppProps extends NextAppProps {
  Component: NextComponentType<NextPageContext, any, any> & { requireAuth?: boolean }
  pageProps: Record<string, any>
  cookies: Record<string, string>
  error?: string
  redirectToPath?: `/${string}`
  slug?: string
  isInvalidSlug?: boolean
  token: string
  session: Session
  user: Me
  config: AppConfig
  flags: Record<string, boolean>
  dehydratedState?: DehydratedState
  isPleaseClosePage?: boolean
  initialBreakpoint: string
}

/* istanbul ignore next */
export const App: FC<AppProps> & AppComposition = ({
  Component,
  pageProps,
  error,
  redirectToPath,
  // "live" props - they _have_ to be returned accurately from `getInitialProps`
  cookies,
  slug,
  isInvalidSlug = false,
  // "page-load" props - they need to be returned correctly for full refreshes, but not client navs
  session: initSession,
  token: initToken,
  user: initUser,
  config: initConfig,
  flags: initFlags,
  dehydratedState,
  isPleaseClosePage,
  initialBreakpoint,
}) => {
  const [user, setUser] = useState(initUser)
  const [config, setConfig] = useState(initConfig)
  const [flags, setFlags] = useState(initFlags)
  const [{ pageTitle, activeItem }, setNavData] = useState<NavConfig>({})
  const requireAuth = Component.requireAuth ?? true
  const userGroupId = user?.notification_group_id
  const { isMigrationDone, doMigrations } = useMigrator()
  const configCatUser = getConfigCatUser(initSession, cookies, { UserType: user?.user_type })

  useOnce(() => {
    doMigrations()
  })

  useEffect(() => {
    if (isMigrationDone) {
      performIfNativeApp(async () => setupNativeApp(userGroupId))
    }
  }, [userGroupId, isMigrationDone])

  if (slug && isInvalidSlug) {
    return <NotEnabledCompanyError />
  }

  const providerProps = {
    cookies,
    config,
    initConfig,
    setConfig,
    flags,
    setFlags,
    pageTitle,
    activeItem,
    setNavData,
    dehydratedState,
    initialBreakpoint,
  }

  return (
    <>
      <AppHead config={config} pageTitle={pageTitle} />
      <NoServerSideRendering>
        <DatadogRumMonitor />
      </NoServerSideRendering>
      <MockProvider>
        <AuthProviders
          cookies={cookies}
          session={initSession}
          authContext={{ initToken, slug, user, setUser }}
        >
          <ConfigCatProvider configCatUser={configCatUser}>
            <RollbarProviders {...{ user, providerProps, activeItem, MainAppScreen }}>
              <MainAppScreen {...{ providerProps, activeItem, isPleaseClosePage }}>
                <TokenWatcher />
                <IntercomScript config={config} />
                <CheckAuthAndAppErrors
                  error={error}
                  redirectToPath={redirectToPath}
                  requireAuth={requireAuth}
                >
                  <Component {...pageProps} />
                </CheckAuthAndAppErrors>
              </MainAppScreen>
            </RollbarProviders>
          </ConfigCatProvider>
        </AuthProviders>
      </MockProvider>
    </>
  )
}

function AppHead({ config, pageTitle }: { config: AppConfig; pageTitle: string }) {
  const companyName = config?.company?.company_name
  const pageNameTitle = pageTitle ? `${pageTitle} | ` : ''
  const companyNameTitle = companyName ? `${companyName} | ` : ''

  return (
    <Head>
      <ViewportMeta />
      <link rel="icon" href="/favicon.ico" />
      <title>{`${pageNameTitle}${companyNameTitle}Bushel Grower App`}</title>
      <link rel="apple-touch-icon" href="/apple-touch-icon.png" sizes="192x192" />
      <link rel="apple-touch-icon" href="/apple-touch-icon-precomposed.png" sizes="192x192" />
      <link rel="apple-touch-icon" href="/apple-touch-icon-120x120.png" sizes="120x120" />
      <link
        rel="apple-touch-icon"
        href="/apple-touch-icon-120x120-precomposed.png"
        sizes="120x120"
      />
      <link rel="apple-touch-icon" href="/apple-touch-icon-152x152.png" sizes="152x152" />
      <link
        rel="apple-touch-icon"
        href="/apple-touch-icon-152x152-precomposed.png"
        sizes="152x152"
      />
    </Head>
  )
}

function ViewportMeta() {
  const contentWithoutZooming =
    'width=device-width, initial-scale=1.0, user-scalable=no viewport-fit=cover'

  return <meta name="viewport" content={contentWithoutZooming} />
}

type MainAppScreenProps = PropsWithChildren<{
  providerProps: AppProvidersProps
  isPleaseClosePage?: boolean
}>

function MainAppScreen({ isPleaseClosePage, providerProps, children }: MainAppScreenProps) {
  // Matomo track user id across route changes:
  useMatomoTrackSlugChange()

  if (isPleaseClosePage) {
    return <AppProviders {...providerProps}>{children}</AppProviders>
  }

  return (
    <AppProviders {...providerProps}>
      <Layout>{children}</Layout>
    </AppProviders>
  )
}

//*** Initialize Back Button Listener */
if (getClient().isAndroid) {
  initBackButtonListener()
}

function useMigrator() {
  const [isMigrationDone, setIsMigrationDone] = useState(false)

  async function doMigrations() {
    await migratePhase1AnonymousGroupId()
    await migrateFavoriteLocationIds()
    setIsMigrationDone(true)
  }

  return { isMigrationDone, doMigrations }
}

import { ComponentType, Dispatch, PropsWithChildren, SetStateAction, useMemo, useRef } from 'react'
import { ErrorBoundary as RollbarErrorBoundary, Provider as RollbarProvider } from '@rollbar/react'
import { ChakraProvider } from '@chakra-ui/react'
import {
  HydrationBoundary,
  QueryClientProvider,
  type DehydratedState,
  QueryClient,
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { queryClientConfigFactory, SERVER_GC_TIME } from 'src/data/queries'
import { AppConfigContext, NavConfig } from 'src/data/config'
import { ErrorBoundaryFallback } from 'src/app/errors'
import { flagr, rollbarConfig } from 'src/utils'
import { Theme } from 'src/themes'
import { AppConfig } from 'src/api/centre-service/data-models/app-config'
import { InitialBreakpointProvider } from 'src/components/designsystem/breakpoint'

export function AppThemeProvider({ children }) {
  return (
    <ChakraProvider resetCSS theme={Theme}>
      {children}
    </ChakraProvider>
  )
}

///////////////////////////////////////////////////////////////////////////////////////////////////

const { FlagrContext } = flagr

export interface AppProvidersProps {
  cookies: Record<string, string>
  config: AppConfig
  flags: Record<string, boolean>
  initConfig: AppConfig
  pageTitle: string
  activeItem: string
  setConfig: Dispatch<SetStateAction<AppConfig>>
  setFlags: Dispatch<SetStateAction<Record<string, boolean | string>>>
  setNavData: Dispatch<React.SetStateAction<NavConfig>>
  dehydratedState?: DehydratedState
  initialBreakpoint: string
}

export function AppProviders({
  cookies,
  config,
  initConfig,
  setConfig,
  flags,
  setFlags,
  pageTitle,
  activeItem,
  setNavData,
  children,
  dehydratedState,
  initialBreakpoint,
}: PropsWithChildren<AppProvidersProps>) {
  const appConfigContextValue = useMemo(
    () => ({
      cookies,
      config: config ?? initConfig,
      setConfig,
      pageTitle,
      activeItem,
      setNavData,
    }),
    [cookies, config, initConfig, setConfig, pageTitle, activeItem, setNavData]
  )
  const flagContextValue = useMemo(() => ({ flags, setFlags }), [flags, setFlags])

  const queryClient = useRef(
    new QueryClient(
      queryClientConfigFactory({
        gcTime: typeof window === 'undefined' ? SERVER_GC_TIME : undefined,
      })
    )
  )

  return (
    <QueryClientProvider client={queryClient.current}>
      <HydrationBoundary state={dehydratedState}>
        <FlagrContext.Provider value={flagContextValue}>
          <AppConfigContext.Provider value={appConfigContextValue}>
            <InitialBreakpointProvider initialBreakpoint={initialBreakpoint}>
              <AppThemeProvider>{children}</AppThemeProvider>
            </InitialBreakpointProvider>
          </AppConfigContext.Provider>
        </FlagrContext.Provider>
        <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-right" />
      </HydrationBoundary>
    </QueryClientProvider>
  )
}

///////////////////////////////////////////////////////////////////////////////////////////////////

interface RollbarProvidersProps {
  user: Me
  providerProps: AppProvidersProps
  MainAppScreen: ComponentType<{ providerProps: AppProvidersProps }>
}

export function RollbarProviders({
  user,
  providerProps,
  MainAppScreen,
  children,
}: PropsWithChildren<RollbarProvidersProps>) {
  return (
    <RollbarProvider
      config={
        // The useless fallback to an empty object squealches an annoying PropTypes warning in tests
        rollbarConfig({ user }) ?? {}
      }
    >
      <RollbarErrorBoundary
        fallbackUI={({ error, resetError }) => (
          <ErrorBoundaryFallback {...{ providerProps, error, resetError, MainAppScreen }} />
        )}
      >
        {children}
      </RollbarErrorBoundary>
    </RollbarProvider>
  )
}

import { useState, useCallback, useMemo, PropsWithChildren } from 'react'
import {
  Box,
  Button,
  Flex,
  Stack,
  HStack,
  ErrorIcon,
  FormControl,
  FormErrorMessage,
  Img,
  Textarea,
  Text,
  VStack,
  PaperPlaneIcon,
} from 'src/components/designsystem'
import {
  useReportIssueStore,
  useFeatureSelection,
  useSubmitStep,
  useUnload,
} from 'src/components/report-issue/helpers'
import { Header } from 'src/components/report-issue/Header'
import { FeatureList } from 'src/components/report-issue/FeatureList'
import { ContactInfo } from 'src/components/help/Help'
import { useConfig } from 'src/data/config'
import { Step } from './Step'

function Content({ children }: PropsWithChildren<any>) {
  return <Box mb={10}>{children}</Box>
}

function MessageForm({ hasError, isSubmitting, value, setValue, onSubmit }) {
  const handler = useCallback(
    (e) => {
      if (value !== '') {
        e.preventDefault()
        e.returnValue = ''
      }
    },
    [value]
  )

  useUnload(handler)
  return (
    <Box as="form" onSubmit={onSubmit} mt={6}>
      <FormControl isInvalid={hasError}>
        <Textarea
          value={value}
          placeholder="Describe your issue..."
          onChange={(e) => setValue(e.target.value)}
          height={40}
        />
        <FormErrorMessage>
          <ErrorIcon mr={1} />
          Please describe the issue you are experiencing
        </FormErrorMessage>
      </FormControl>

      <Flex justifyContent="flex-end" mt={5}>
        <Button
          type="submit"
          variant="primary"
          rightIcon={<PaperPlaneIcon />}
          isLoading={isSubmitting}
        >
          Submit
        </Button>
      </Flex>
    </Box>
  )
}

// START
///////////////////////////////////////////////////////////////////////////////////////////////////

export function StartStep() {
  const { stepForward, stepForwardWithData } = useReportIssueStore()

  const types = useMemo(() => {
    return [
      {
        key: 'data_missing',
        label: 'Data is missing',
        onClick: () => {
          stepForwardWithData(Step.MISSING_DATA_FEATURE, { problem: 'data_missing' })
        },
      },
      {
        key: 'cant_login',
        label: "I can't sign in",
        onClick: () => {
          stepForward(Step.CANT_LOG_IN)
        },
      },
      {
        key: 'data_incorrect',
        label: 'Data is incorrect',
        onClick: () => {
          stepForwardWithData(Step.INCORRECT_DATA_FEATURE, { problem: 'data_incorrect' })
        },
      },
      {
        key: 'other',
        label: 'Other',
        onClick: () => {
          stepForwardWithData(Step.OTHER_ISSUE_SUBMIT, { problem: 'other' })
        },
      },
    ]
  }, [stepForward, stepForwardWithData])

  return (
    <>
      <Content>
        <Box mt={5}>
          <Header
            showBack={false}
            title="Report an Issue"
            preamble="We rely on your feedback to help us improve your app. Please use these prompts below to
          provide feedback."
          />
        </Box>

        <Box mt={16}>Select feedback type:</Box>

        <Box mt={6}>
          <Stack spacing={4}>
            {types.map((type) => {
              return (
                <Button key={type.key} variant="outline" w="100%" onClick={type.onClick}>
                  {type.label}
                </Button>
              )
            })}
          </Stack>
        </Box>
      </Content>
    </>
  )
}

// MISSING DATA
///////////////////////////////////////////////////////////////////////////////////////////////////

export function MissingDataFeatureStep() {
  const { stepForwardWithData } = useReportIssueStore()
  const features = useFeatureSelection()
  const onClickHandler = useCallback(() => {
    stepForwardWithData(Step.MISSING_DATA_SUBMIT, { feature_name: 'other' })
  }, [stepForwardWithData])

  const items = features.map((item) => ({
    ...item,
    onClick: () =>
      stepForwardWithData(Step.MISSING_DATA_SUBMIT, { feature_name: item.featureName }),
  }))

  return (
    <Content>
      <Header title="Missing Data" subtitle="" preamble="What feature is missing data?" />
      <Box mt={6}>
        <FeatureList items={items} />
      </Box>
      <Flex justifyContent="flex-end" mt={5}>
        <Button variant="ghost" onClick={onClickHandler}>
          Skip
        </Button>
      </Flex>
    </Content>
  )
}

export function MissingDataSubmitStep() {
  const { value, setValue, hasError, isSubmitting, featureName, onSubmit } = useSubmitStep()

  return (
    <Content>
      <Header
        title=""
        subtitle={`Missing Data: ${featureName}`}
        preamble="Use the section below to provide additional detail that may help us diagnose and fix the issue you are experiencing."
      />

      <Box mt={6}>
        <MessageForm {...{ hasError, isSubmitting, value, setValue, onSubmit }} />
      </Box>
    </Content>
  )
}

// INCORRECT DATA
///////////////////////////////////////////////////////////////////////////////////////////////////

export function IncorrectDataFeatureStep() {
  const { stepForwardWithData } = useReportIssueStore()
  const features = useFeatureSelection()

  const items = features.map((item) => ({
    ...item,
    onClick: () => onClick(item.featureName),
  }))
  const onClick = (featureName: string) =>
    stepForwardWithData(Step.INCORRECT_DATA_SUBMIT, { feature_name: featureName })

  const skipOnClickHandler = () => {
    stepForwardWithData(Step.INCORRECT_DATA_SUBMIT, { feature_name: 'other' })
  }

  return (
    <Content>
      <Header
        title="Incorrect Data"
        subtitle=""
        preamble="What feature is displaying incorrect data?"
      />
      <Box mt={6}>
        <FeatureList items={items} />
      </Box>

      <Flex justifyContent="flex-end" mt={5}>
        <Button variant="ghost" onClick={skipOnClickHandler}>
          Skip
        </Button>
      </Flex>
    </Content>
  )
}

export function IncorrectDataSubmitStep() {
  const { value, setValue, hasError, isSubmitting, featureName, onSubmit } = useSubmitStep()

  return (
    <Content>
      <Header
        title=""
        subtitle={`Incorrect Data: ${featureName}`}
        preamble="Use the section below to provide additional detail that may help us diagnose and fix the issue you are experiencing."
      />

      <Box mt={6}>
        <MessageForm {...{ hasError, isSubmitting, value, setValue, onSubmit }} />
      </Box>
    </Content>
  )
}

// CAN'T SIGN IN
///////////////////////////////////////////////////////////////////////////////////////////////////

export function CantLogInStep() {
  const { close } = useReportIssueStore()
  const { config } = useConfig()
  const { company } = config || {} // need this to handle no-company case where there is no config

  return (
    <Content>
      <Header
        title="Can't sign in"
        subtitle=""
        preamble="Please contact your company admin to confirm your phone number is in their system correctly."
      />

      <Box mt={6}>
        <ContactInfo company={company} />
      </Box>

      <Flex justifyContent="flex-end" mt={6}>
        <Button variant="outline" onClick={close}>
          Dismiss
        </Button>
      </Flex>
    </Content>
  )
}

// OTHER ISSUES
///////////////////////////////////////////////////////////////////////////////////////////////////

export function OtherIssueSubmit() {
  const { value, setValue, hasError, isSubmitting, onSubmit } = useSubmitStep()

  return (
    <Content>
      <Header
        title="Other Issue"
        subtitle=""
        preamble="Use the section below to provide additional detail that may help us diagnose and fix the issue you are experiencing."
      />

      <MessageForm {...{ hasError, isSubmitting, value, setValue, onSubmit }} />
    </Content>
  )
}

// SUBMIT
///////////////////////////////////////////////////////////////////////////////////////////////////

export function SubmitSuccess() {
  const { close } = useReportIssueStore()

  return (
    <Content>
      <VStack justifyContent="center" spacing={8} pt={10}>
        <Img src="/img/message-sent.png" alt="Message Sent" />
        <Text fontSize={['md', null, '2xl']} fontWeight="bold">
          Feedback submitted
        </Text>
        <Text>
          Thank you for your feedback and your patience as we work to make sure your app performs as
          intended.
        </Text>
        <Box mt={6}>
          <Button width={100} variant="outline" onClick={close}>
            Done
          </Button>
        </Box>
      </VStack>
    </Content>
  )
}

export function SubmitError() {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { close, submitForm } = useReportIssueStore()

  return (
    <Content>
      <VStack justifyContent="center" spacing={8} pt={10}>
        <Img src="/img/message-lost.png" alt="Message Lost" />
        <Text fontSize={['md', null, '2xl']} fontWeight="bold" textAlign="center">
          Oh no, the message was lost
        </Text>
        <Text textAlign="center">
          There seemed to be an error while submitting your feedback. Please try to submit again or
          cancel.
        </Text>
      </VStack>

      <HStack justifyContent="center" spacing={3} mt={6}>
        <Button
          variant="outline"
          isLoading={isSubmitting}
          onClick={async () => {
            setIsSubmitting(true)
            await submitForm()
            setIsSubmitting(false)
          }}
        >
          Try Again
        </Button>

        <Button variant="outline" isDisabled={isSubmitting} onClick={close}>
          Cancel
        </Button>
      </HStack>
    </Content>
  )
}

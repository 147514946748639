import { allAuthzPermissions } from 'src/api/bidl/iam/authz-permissions'
import { useTenantId } from 'src/data/queries/config'
import { type LookupResourcesQueryOptions, useLookupResources } from './useLookupResources'

export default function useListTenantPermissions(
  options: Partial<LookupResourcesQueryOptions<string[]>> = {}
) {
  const { data: tenantId } = useTenantId()

  return useLookupResources<string[]>(
    { permissions: allAuthzPermissions, tenantId },
    {
      ...options,
      select: (data) =>
        data.results
          .filter((result) => {
            return result.root || result.tenants.some((tenant) => tenant.tenantId === tenantId)
          })
          .map((result) => result.permission),
    }
  )
}

import { useFeatureFlag } from 'configcat-react'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { useEffect, useMemo } from 'react'
import { AppConfig } from 'src/api/centre-service/data-models/app-config'
import { useAuth } from 'src/auth'
import {
  Box,
  HelpChatActiveIcon,
  HelpChatStandardIcon,
  IconButton,
} from 'src/components/designsystem'
import { ENV } from 'src/utils'
import { CONFIGCAT_FLAGS } from 'src/utils/config-cat'
import { create } from 'zustand'

declare global {
  interface Window {
    Intercom: any
    intercomSettings: any
  }
}

type IntercomStore = {
  isVisible: boolean
  setIsVisible: (isVisible: boolean) => void
  unreadCount: number
  setUnreadCount: (unreadCount: number) => void
}

export const useIntercomStore = create<IntercomStore>()((set) => ({
  isVisible: false,
  setIsVisible: (isVisible: boolean) => set({ isVisible }),
  unreadCount: 0,
  setUnreadCount: (unreadCount: number) => set({ unreadCount }),
}))

/**
 * Injects the Intercom script into the page and initializes it with the current user's data.
 * @param config - The app config
 * @returns Intercom script wrapped in a next/script component
 */
export function IntercomScript({ config }: Readonly<{ config: AppConfig }>) {
  const { events } = useRouter()
  const { user, slug, session, isStaff } = useAuth()
  const { value: isIntercomFlagEnabled, loading } = useFeatureFlag(
    CONFIGCAT_FLAGS.isIntercomIntegrationEnabled,
    false
  )
  // Store with atomic selectors
  const { setIsVisible, setUnreadCount } = useIntercomStore()

  const intercomUserInfo = useMemo(() => {
    if (!user || !session || !config) return null
    const name = [user?.first_name, user?.last_name].join(' ').trim()
    return {
      name,
      email: session.user.email,
      user_id: user?.id,
      user_type: user.user_type,
      company: {
        slug,
        company_id: config?.company?.id,
        name: config?.company?.company_name,
        phone: config?.company?.phone,
        website: config?.company?.website,
        country: config?.company?.country,
      },
    }
  }, [config, session, slug, user])

  // Wait for userData, then boot Intercom inside an effect to ensure it's only called client-side
  useEffect(() => {
    if (!isIntercomFlagEnabled || !isStaff) return
    window.Intercom('onShow', () => setIsVisible(true))
    window.Intercom('onHide', () => setIsVisible(false))
    window.Intercom('onUnreadCountChange', (count: number) => setUnreadCount(count))
    window.Intercom('boot', {
      ...intercomUserInfo,
      app_id: ENV.INTERCOM_APP_ID,
      alignment: 'right',
      vertical_padding: 0,
      horizontal_padding: -10,
      hide_default_launcher: true,
      custom_launcher_selector: '#intercom-launcher',
    })
  }, [intercomUserInfo, isIntercomFlagEnabled, isStaff, setIsVisible, setUnreadCount])

  // Update Intercom state on route change
  useEffect(() => {
    if (!isIntercomFlagEnabled) return
    const handleRouteChange = () => window.Intercom('update')
    events.on('routeChangeComplete', handleRouteChange)
    return () => events.off('routeChangeComplete', handleRouteChange)
  }, [events, isIntercomFlagEnabled])

  if (!isIntercomFlagEnabled || loading) return null

  return (
    <Script id="intercom-script">
      {`(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${ENV.INTERCOM_APP_ID}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`}
    </Script>
  )
}

export function IntercomHelpButton() {
  const { unreadCount, isVisible } = useIntercomStore()
  const isIntercomInitialized = typeof window !== 'undefined' && !!window.Intercom

  if (!isIntercomInitialized) return null

  return (
    <Box pos="relative">
      <IconButton
        pt={1}
        pr={1}
        fontSize="2xl"
        variant="link"
        aria-label="Help"
        id="intercom-launcher"
        icon={isVisible ? <HelpChatActiveIcon /> : <HelpChatStandardIcon />}
      />
      {unreadCount > 0 && (
        <Box
          pos="absolute"
          bgColor="red.500"
          top={-1}
          right={-1}
          rounded="full"
          boxSize={4}
          pt={0.5}
          outline="2px solid white"
          display="flex"
          alignItems="center"
          justifyContent="center"
          fontSize="sm"
          fontWeight="bold"
          color="white"
        >
          {unreadCount}
        </Box>
      )}
    </Box>
  )
}

import { Fragment, ReactNode } from 'react'
import {
  Box,
  Button,
  ChakraModal,
  Divider,
  ModalBody,
  ModalContent,
  ModalOverlay,
  UseDisclosureProps,
  useBreakpoint,
} from 'src/components/designsystem'
import { create } from 'zustand'

type ActionMenuStore<TData> = {
  item: TData
  setItem: (item: TData) => void
} & Pick<UseDisclosureProps, 'onClose' | 'onOpen' | 'isOpen'>

/**
 * Create a single store instance at the module level.
 * - Note that the store is initially created with a type of `unknown`, which is not ideal,
 * but TypeScript currently has limitations in typing Zustand stores created in this manner.
 * - To achieve full type-safety with this function it must be **inferred** by a consuming hook.
 *   - `useActionMenuStore` accepts a closure that is passed the store `state`. This closure can
 *     be used to infer the type of the store using a *circular reference pattern*, while also
 *     making use of the *atomic selector pattern* (optional).
 *   - Provide your desired type (or generic) for the first closure parameter given to this
 *     function, and then re-export the store state.
 *   - See the example below for usage.
 * @see useActionMenuModal - For usage and generic type resolution.
 * @returns ActionMenuStore instance from Zustand.
 */
const useActionMenuStore = create<ActionMenuStore<unknown>>()((set) => ({
  item: null,
  isOpen: false,
  setItem: (item) => set({ item }),
  onOpen: () => set({ isOpen: true }),
  onClose: () => set({ item: null, isOpen: false }),
}))

/**
 * A hook for managing the state of the ActionMenuModal component.
 * - Note this does not take advantage of the *atomic selector pattern*
 *   since we're returning the entire store state.
 * @param TData - The type of the item passed to the modal.
 * @returns ActionMenu state as a Zustand store instance.
 * @example
 * ```tsx
 * const actionMenuModal = useActionMenuModal<Settlement>()
 * ```
 */
export const useActionMenuModal = <TData,>() => useActionMenuStore() as ActionMenuStore<TData>

interface ActionMenuModalProps<Item> {
  actionMenuConfig?: {
    key: string
    render?: (item: Item) => ReactNode
  }[]
  actionMenuModalIsOpen: boolean
  actionMenuModalOnClose: () => void
  item: Item
}

export default function ActionMenuModal({
  actionMenuConfig,
  actionMenuModalIsOpen,
  actionMenuModalOnClose,
  item,
}: ActionMenuModalProps<any>) {
  const { isMobile } = useBreakpoint()

  if (!isMobile) return null

  return (
    <ChakraModal
      isOpen={actionMenuModalIsOpen}
      onClose={actionMenuModalOnClose}
      scrollBehavior="outside"
      isCentered
      size="xs"
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />

      <ModalContent bg="white">
        <ModalBody p={0}>
          <Box py={4} px={2} w="100%" textAlign="left">
            {actionMenuConfig?.map((config) => (
              <Fragment key={config.key}>{config.render(item)}</Fragment>
            ))}
          </Box>
          <Divider />
          <Box textAlign="center" w="100%" p={2}>
            <Button
              variant="ghostSecondary"
              w="100%"
              color="gray.500"
              onClick={actionMenuModalOnClose}
              data-testid="cancel-button"
            >
              Cancel
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </ChakraModal>
  )
}

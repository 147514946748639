import { useCallback } from 'react'
import { useRouter } from 'next/router'
import { create } from 'zustand'
import { trackEvent } from 'src/utils/analytics'
import useHasCrmAccess from 'src/utils/hooks/useHasCrmAccess'

type CRMSearchStore = {
  isSearching: boolean
  search: string
  openSearch: () => void
  closeSearch: () => void
  setSearch: (search: string) => void
}

const useCRMSearchStore = create<CRMSearchStore>()((set) => ({
  isSearching: false,
  search: '',
  openSearch: () => {
    set({ isSearching: true })
    trackEvent('Customer View', 'Start Search')
  },
  closeSearch: () => {
    set({ isSearching: false, search: '' })
    trackEvent('Customer View', 'End Search')
  },
  setSearch: (search: string) => {
    set({ search: search })
  },
}))

export function useCustomerSearchDisclosure() {
  const { query, push } = useRouter()
  const { search, isSearching, openSearch, closeSearch, setSearch } = useCRMSearchStore()
  const { hasCrmManagePermission } = useHasCrmAccess()
  const company = query.company?.toString()

  return {
    search,
    setSearch,
    isEnabled: hasCrmManagePermission,
    isSearching,
    openSearch,
    closeSearch,

    handleSelectCustomer: useCallback(
      (customer: SearchedCustomer) => {
        push(`/${company}/staff/crm/${encodeURIComponent(customer.id)}`, undefined, {})
        trackEvent('Customer View', 'Selected Customer')
        closeSearch()
      },
      [push, company, closeSearch]
    ),
  }
}

import { useBreakpoint as useChakraBreakpoint } from '@chakra-ui/react'
import { createContext, useContext, useMemo, useRef } from 'react'
import { createStore, useStore } from 'zustand'

type InitialBreakpointStore = {
  initialBreakpoint: ChakraBreakpoint
}

type BreakpointStore = ReturnType<typeof createBreakpointStore>

const createBreakpointStore = (initialBreakpoint: ChakraBreakpoint) => {
  const DEFAULT_PROPS: InitialBreakpointStore = { initialBreakpoint: null }
  return createStore<InitialBreakpointStore>()(() => ({
    ...DEFAULT_PROPS,
    initialBreakpoint,
  }))
}

export const InitialBreakpointContext = createContext<BreakpointStore | null>(null)

export function InitialBreakpointProvider({ children, initialBreakpoint }) {
  const store = useRef(createBreakpointStore(initialBreakpoint)).current
  return (
    <InitialBreakpointContext.Provider value={store}>{children}</InitialBreakpointContext.Provider>
  )
}

export function useInitialBreakpoint() {
  const store = useContext(InitialBreakpointContext)
  if (!store) {
    throw new Error('useInitialBreakpoint must be used within a InitialBreakpointProvider')
  }
  return useStore(store)
}

type UseBreakpoint = {
  breakpoint: ChakraBreakpoint
  isDesktop: boolean
  isMobile: boolean
}

export default function useBreakpoint(): UseBreakpoint {
  const { initialBreakpoint } = useInitialBreakpoint()
  const breakpoint = useChakraBreakpoint({ fallback: initialBreakpoint })
  const values = useMemo(() => deriveBreakpointState(breakpoint), [breakpoint])

  return values
}

const DESKTOP_BREAKPOINTS: ChakraBreakpoint[] = ['lg', 'xl', '2xl']
const MOBILE_BREAKPOINTS: ChakraBreakpoint[] = ['base', 'xs', 'sm']

/**
 * Takes a string breakpoint value and resolves facts about it, such as whether it is desktop or mobile.
 * !Notably missing is the 'md' breakpoint handling. Not sure if it is needed.
 * @param breakpoint - The breakpoint value
 * @returns An object with the breakpoint and whether it is desktop or mobile
 */
export function deriveBreakpointState(breakpoint: ChakraBreakpoint): UseBreakpoint {
  return {
    breakpoint,
    isDesktop: DESKTOP_BREAKPOINTS.includes(breakpoint),
    isMobile: MOBILE_BREAKPOINTS.includes(breakpoint),
  }
}

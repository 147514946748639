import { queryOptions, useQuery } from '@tanstack/react-query'
import { allAuthzPermissions } from 'src/api/bidl/iam/authz-permissions'
import { LookupResourcesResponse } from 'src/api/bidl/iam/access-evaluate'
import * as api from 'src/api'
import { HTTPError } from 'src/api'

type LookupResourcesOptionsArgs = {
  permissions?: string[]
  tenantId?: string
}

export function lookupResourcesOptions<T>({
  permissions = allAuthzPermissions,
  tenantId,
}: LookupResourcesOptionsArgs) {
  return queryOptions<LookupResourcesResponse, HTTPError, T>({
    queryKey: ['lookup-resources', { permissions, tenantId }],
    queryFn: () => api.iamService.lookupResources(permissions, tenantId),
    enabled: !!tenantId,
    gcTime: Infinity,
  })
}

export type LookupResourcesQueryOptions<T> = ReturnType<typeof lookupResourcesOptions<T>>

export function useLookupResources<T>(
  { permissions, tenantId }: LookupResourcesOptionsArgs,
  { enabled: overrideEnabled, ...options }: Partial<LookupResourcesQueryOptions<T>> = {}
) {
  const { enabled: baseEnabled, ...baseOptions } = lookupResourcesOptions<T>({
    permissions,
    tenantId,
  })
  return useQuery({
    ...baseOptions,
    ...options,
    enabled: baseEnabled && overrideEnabled,
  })
}
